body{
  @apply bg-lightGray;
}
a:hover{
  @apply text-primary
}
.btn {
  @apply my-5 rounded-[10px] px-5 py-2 text-center mx-auto md:w-[350px]  bg-primary text-white sm:text-[20px]  text-[14px] uppercase  block;
}
.firstScreen{
  @apply bg-dark-img h-[830px] w-full bg-center;
}
.header{
  @apply text-white py-5 container mx-auto flex items-center justify-between;
}
.navBrand{
  @apply flex items-center
}
.logo{
   width:65px;
}
.mobNav .logo{
    width:35px;
}
.headerSocials{
  @apply lg:flex hidden items-center space-x-5;
}
//.hsIcon{
//  @apply inline
//}
.hsIcon svg{
  @apply w-[20px] h-[20px] text-lightGray hover:text-white
}
.fsIcon svg{
  @apply w-[20px] h-[20px] text-[#383838] hover:text-primary
}
.nav{
  @apply lg:flex hidden items-center  space-x-10
}
.navItem{
  @apply lg:mb-0 uppercase whitespace-nowrap text-lightGray text-[18px] cursor-pointer
         hover:text-white

}
.mobNav{
  @apply lg:hidden bg-white flex flex-col absolute w-[300px] h-full bottom-0 right-0 shadow-lg opacity-80 p-5 

}
.sectionTitle{
  @apply uppercase font-semibold text-[36px] text-[#383838] my-10 justify-center flex;
}
.bulletLi{
  @apply before:content-['\2022'] before:text-primary text-[14px] md:text-[16px] before:inline-block before:w-3 before:-ml-3 before:font-medium font-light;
}