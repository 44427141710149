@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat/Montserrat-Light.ttf');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat/Montserrat-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat/Montserrat-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat/Montserrat-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}
